import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Container } from '../common';
import goki from '../images/goki.png';
const AlertBox = styled.div`
  color: #b60300;
  a {
    color: #b60300;
  }
  padding: 1rem 2rem;
  border: 1px solid #b60300;
  text-align: center;
  margin-bottom: 1rem;
`;
const Alert = () => {
  return (
    <Container>
      <div style={{ textAlign: 'center', margin: '1rem 0' }}>
        <img src={goki} alt="Goki" align="center" />
        <p style={{ fontWeight: '700' }}>
          All of our hostels have contactless check-in with Goki -{' '}
          <Link to="/about/goki/" target="_blank">
            Learn more here{' '}
          </Link>
        </p>
      </div>
      <AlertBox>
        <Link to="/about/covid-19" target="_blank">
          Click here to see information about Covid-19 and staying with us
        </Link>
      </AlertBox>
    </Container>
  );
};

export default Alert;
