import React from 'react';
import { Helmet } from 'react-helmet';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import {
  Section,
  Container,
  ScrollContainer,
  Button
} from '../components/common';
import {
  Header,
  Faq,
  Accom,
  Deal,
  StickyNav,
  Gallery,
  Location
} from '../components/layout/index.js';

import useTranslations from '../components/layout/useTranslations';

import Cross from './cross.png';
import Alert from '../components/layout/Alert';

const ActivityCard = styled.div`
  background: ${props => props.theme.white};
  padding: 1.5rem;
  box-shadow: ${props => props.theme.shadow};
  margin-right: 15px;
  display: inline-block;
  width: 17rem;
  @supports (display: grid) {
    margin-right: 0;
    width: 17rem;
  }
`;

const IntroContainer = styled(Container)`
  position: relative;
  max-width: 900px;
`;

const Intro = styled.div``;
const ActivityHeading = styled.h3`
  color: ${props => props.theme.secondaryColor};
  margin-bottom: 1rem;
`;

const ActivityText = styled.p`
  color: ${props => props.theme.lightGreyText};
  font-size: 0.9rem;
  margin-bottom: 0rem;
`;

const Time = styled.p`
  font-weight: bold;
  font-size: 0.8rem;
  padding-bottom: 0.5rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  border-bottom: 1px solid ${props => props.theme.lightBlueLine};
`;

const ActivityLeadCard = styled(ActivityCard)`
  background: ${props => props.theme.secondaryColor};
  color: #fff;
  position: relative;
  box-shadow: ${props => props.theme.shadow};
`;

const ActivityLeadText = styled.p`
  color: ${props => props.theme.lightBlueText};
  font-size: 1.286rem;
  line-height: 2rem;
`;

const Crossimage = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 0;
`;

const HostelPage = props => {
  const { data } = props;

  // Determins the language of the page via context
  const lang = props.pageContext.locale;

  const {
    subline,
    bookNow,
    viewGallery,
    rooms,
    activities,
    facilities,
    location,
    faq,
    stay,
    perPerson,
    checkAvail,
    bestPrice,
    socialSpot,
    activtySub,
    daytime,
    nighttime
  } = useTranslations();

  const {
    title,
    slug,
    intro,
    thingsNearBy,
    featuredImage,
    emailAddress,
    phone,
    city,
    streetAddress,
    accommodationType,
    country,
    postcode,
    state,
    destinationName,
    mewsProperty,
    deal
  } = data.datoCmsHostel;

  // Image work on page bellow

  const featureImages = data.datoCmsHostel.featureGallery.map(photo =>
    Object.assign({
      srcSet: photo.fluid.srcSet,
      src: photo.fluid.src,
      caption: photo.title,
      fluid: photo.fluid,
      alt: photo.alt
    })
  );

  const activitiesImages = data.datoCmsHostel.activitiesGallery.map(photo =>
    Object.assign({
      srcSet: photo.fluid.srcSet,
      src: photo.fluid.src,
      caption: photo.title,
      fluid: photo.fluid,
      alt: photo.alt
    })
  );

  const accomGal = data.datoCmsHostel.accommodationType.map((block, index) => {
    const roomGals = block.roomGallery.map(photo =>
      Object.assign({
        srcSet: photo.fluid.srcSet,
        src: photo.fluid.src,
        caption: photo.caption,
        fluid: photo.fluid,
        alt: photo.alt
      })
    );
    return roomGals;
  });

  const combineAccom = [].concat.apply([], accomGal);
  const cominedGallery = featureImages.concat(activitiesImages, combineAccom);

  // schema mark up

  const rootUrl = `${data.site.siteMetadata.siteUrl}`;
  const pagePath = `/hostels/${slug}`;
  const postURL = rootUrl + pagePath;

  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'Hostel',
      url: postURL,
      brand: 'Base Backpackers',
      // logo: SiteLogo,
      image: `${featuredImage.url}`,
      name: `${title}`,
      description: `${intro}`,
      telephone: `${phone}`,
      email: `${emailAddress}`,
      priceRange: `Stay from $${accommodationType[0].priceFrom}`,
      address: {
        '@type': 'PostalAddress',
        addressLocality: `${city}`,
        addressRegion: `${state}`,
        postalCode: `${postcode}`,
        streetAddress: `${streetAddress}`,
        addressCountry: {
          '@type': 'Country',
          name: `${country}`
        }
      }
    }
  ];

  const hasDeals = deal.length > 0 ? true : false;

  return (
    <>
      <HelmetDatoCms seo={data.datoCmsHostel.seoMetaTags} />

      <Helmet>
        {mewsProperty && (
          <script>
            {`(function(m,e,w,s){c=m.createElement(e);c.onload=function(){
        Mews.D.apply(null,s)};c.async=1;c.src=w;t=m.getElementsByTagName(e)[0];t.parentNode.insertBefore(c,t);})
        (document,'script','https://www.mews.li/distributor/distributor.min.js',[['${
          data.datoCmsHostel.mewsId
        }']]);`}
          </script>
        )}

        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
        <link rel="dns-prefetch" href="//www.mews.li" />
        <link rel="dns-prefetch" href="//cdn.mews.li" />
      </Helmet>

      {/* Header section here */}

      <Header
        hostel
        backgroundImage={featuredImage.fluid}
        poster={featuredImage.url}
        pageTitle={title}
        tagline={subline}
        propertyName={title}
        caption={featuredImage.title}
        alt={featuredImage.alt}
        gal={cominedGallery}
        button={mewsProperty && 'hostel'}
      />

      <Section lightBlueBackground>
        <IntroContainer maxwidth="1000px" width="700px" mobcol="1fr">
          <Intro dangerouslySetInnerHTML={{ __html: intro }} />
        </IntroContainer>
      </Section>

      <StickyNav mewsProperty={mewsProperty} deal={hasDeals} />

      {/* Accommodation section here */}
      <Section id="rooms" padding="0rem 3rem 3rem">
        <Alert />
        <Accom
          source={data.datoCmsHostel.accommodationType}
          hostelName={title}
          mewsProperty={mewsProperty}
        />
      </Section>

      {/* Deals section goes here id a deal exists*/}
      {deal && (
        <Section id="deal" padding="0rem 3rem 3rem">
          <Container>
            <h2>Deals</h2>
          </Container>
          <Container>
            <Deal dealData={deal} />
          </Container>
        </Section>
      )}

      {/* Activities section here */}
      <Section id="activities" lightBackground>
        <Container>
          <h2>{activities}</h2>
        </Container>
        <ScrollContainer
          padding="0 0 1rem"
          maxwidth="960px"
          colwidth="17rem"
          className="scroll"
        >
          <ActivityLeadCard>
            <h3>{socialSpot}</h3>
            <ActivityLeadText>{activtySub}</ActivityLeadText>
            <Crossimage src={Cross} alt="base cross" />
          </ActivityLeadCard>

          {data.datoCmsHostel.activities.map(block => (
            <div key={block.id}>
              <ActivityCard key={block.id}>
                <ActivityHeading>{block.day}</ActivityHeading>
                <ActivityText>{block.amActivity}</ActivityText>
                <Time>{daytime}</Time>
                <ActivityText>{block.pmActivity}</ActivityText>
                <Time>{nighttime}</Time>
              </ActivityCard>
            </div>
          ))}
        </ScrollContainer>

        <Container
          col={activitiesImages.length}
          gap=".5rem"
          mobcol="1fr 1fr"
          margin="1rem auto"
        >
          <Gallery images={activitiesImages} />
        </Container>
      </Section>

      {/* Faciliteis section here */}
      <Section id="facilities">
        <Container>
          <h2>{facilities}</h2>
        </Container>
        <Container col="4" gap="1rem" mobcol="1fr 1fr">
          <Gallery images={featureImages} />
          {mewsProperty && (
            <Button primary large className="distributor">
              {checkAvail}
            </Button>
          )}
        </Container>
      </Section>

      {/* Location section here */}
      <Location
        title={title}
        streetAddress={streetAddress}
        city={city}
        latitude={data.datoCmsHostel.location.latitude}
        longitude={data.datoCmsHostel.location.longitude}
        mapScreenShot={data.datoCmsHostel.mapScreenShot.fluid}
        phone={phone}
        emailAddress={emailAddress}
        thingsNearBy={thingsNearBy}
      />

      {/* FAQ */}
      <Section id="faq">
        <Container>
          <h2>{faq}</h2>
        </Container>
        <Container col="1" mobcol="1fr" gap="1rem">
          <div>
            {data.datoCmsHostel.yes.map(block => (
              <div key={block.id} className={block.model.apiKey}>
                <Faq question={block.question} answer={block.answer} />
              </div>
            ))}
            <p>
              {mewsProperty && (
                <Button primary large className="distributor">
                  {checkAvail}
                </Button>
              )}
            </p>
          </div>
          <div />
        </Container>
      </Section>
    </>
  );
};

export default HostelPage;
//  code change to push update

export const query = graphql`
  query HostelPageQuery($slug: String!, $locale: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    datoCmsHostel(locale: { eq: $locale }, slug: { eq: $slug }) {
      title
      intro
      slug
      locale
      mewsId
      mewsProperty
      country
      destinationName {
        title
      }
      featuredImage {
        url
        alt
        title
        fluid(
          maxWidth: 2000
          imgixParams: {
            fm: "jpg"
            fit: "crop"
            crop: "faces"
            auto: "format"
            q: 50
            w: "2000"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }

      deal {
        id
        title

        promo {
          fluid(
            maxWidth: 2000
            imgixParams: {
              fm: "jpg"
              fit: "crop"
              crop: "faces"
              auto: "format"
              q: 50
              w: "2000"
            }
          ) {
            ...GatsbyDatoCmsFluid
          }
        }
        description
      }

      accommodationType {
        ... on DatoCmsAccom {
          model {
            apiKey
          }
          name
          intro

          priceFrom
          currency
          features
          introText {
            roomDescription
          }
          roomGallery {
            alt
            caption: title
            src: url
            fluid(
              maxWidth: 500
              imgixParams: {
                fm: "jpg"
                fit: "crop"
                crop: "faces"
                auto: "format"
                q: 50
                w: "500"
              }
            ) {
              srcSet
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }

      featureGallery {
        alt
        title
        fluid(
          maxWidth: 1000
          maxHeight: 600
          imgixParams: {
            fm: "jpg"
            fit: "crop"
            crop: "faces"
            auto: "format"
            q: 50
            w: "1000"
          }
        ) {
          srcSet
          ...GatsbyDatoCmsFluid
        }
      }

      activities {
        ... on DatoCmsActivitiesByDay {
          model {
            apiKey
          }

          day
          amActivity
          pmActivity
        }
      }

      activitiesGallery {
        alt
        title
        fluid(
          maxWidth: 800
          maxHeight: 600
          imgixParams: {
            fm: "jpg"
            auto: "format"
            fit: "crop"
            crop: "faces"
            q: 40
            w: "800"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      location {
        latitude
        longitude
      }
      streetAddress
      suburb
      city
      phone
      postcode
      state
      emailAddress

      thingsNearBy {
        tripType
        time
        name
      }

      mapScreenShot {
        fluid(
          maxWidth: 600
          imgixParams: { fm: "jpg", auto: "format", q: 50, w: "600" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }

      yes {
        ... on DatoCmsQA {
          model {
            apiKey
          }

          question
          answer
        }
      }
      seo {
        title
        description
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;
